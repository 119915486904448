import { Component, Input } from '@angular/core';
import { ObtenerDatosService } from 'src/app/servicios/datos/obtener-datos/obtener-datos.service';

@Component({
  selector: 'app-resumen-votacion',
  templateUrl: './resumen-votacion.component.html',
  styleUrls: ['./resumen-votacion.component.scss']
})

export class ResumenVotacionComponent {

  @Input() public extranjero:any = false;
  @Input() public disable_simulation:any = true;
  @Input() public hide_acumulados:any = false;
  
  @Input() public acumulados_num:any = '';
  @Input() public acumulados_prc:any = '';
  @Input() public extranjero_num:any = '';
  @Input() public extranjero_prc:any = '';
  @Input() public noregistradas_num:any = '';
  @Input() public noregistradas_prc:any = '';
  @Input() public nulos_num:any = '';
  @Input() public nulos_prc:any = '';
  @Input() public total_num:any = '';
  @Input() public total_prc:any = '';
  @Input() public observaciones:any = '';
  @Input() public use_observaciones:any = false;
  @Input() public hide_prc:any = false;
  @Input() public hide_legend:any = false;

  config:any = {};
  omitir_extranjero:any = false;
  tooltip:any = '';

  constructor(
    public readonly _obtenerDatosService: ObtenerDatosService,
  ) {
    this._obtenerDatosService.configSubject.subscribe(config => {
      if (config != null) {
        this.config = config;
        if (config.omitir_extranjero === 'true' || config.omitir_extranjero === true) this.omitir_extranjero = true;
      }
    });
  }

  ngOnInit(): void {
    if (this.extranjero === 'true' || this.extranjero === true) this.extranjero = true;
    else this.extranjero = false;

    if (this.disable_simulation === 'true' || this.disable_simulation === true) this.disable_simulation = true;
    else this.disable_simulation = false;

    if (this.use_observaciones === 'true' || this.use_observaciones === true) this.use_observaciones = true;
    else this.use_observaciones = false;

    if (this.hide_prc === 'true' || this.hide_prc === true) this.hide_prc = true;
    else this.hide_prc = false;

    console.log('hide porcentaje', this.hide_prc);
    console.log('this.extranjero', this.extranjero_num);

    this.tooltip = 'Es la sumatoria de los votos obtenidos por Partido Político, ' + ((this._obtenerDatosService as any).config?.estado == 'Oaxaca' ? 'Candidatura Común' : 'Coalición') + '.';

    // $('[data-toggle="tooltip"]').tooltip();
  }

  scrollComponent(component: string) {
    var el = document.getElementById(component);
    if(el){
        el.scrollIntoView();
    }
  }

}
